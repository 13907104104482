export const theme = {
  colors: {
    brick: '#AC4A2A', // rgb(172, 74, 42),
    brickLight: '#cd734c', // rgb(205, 115, 76)
    brickVeryLight: '#d89274', // rgb(216, 146, 116)
    brickDark: '#562515', // rgb(86,37,21)
    plaque: '#5564a5', // rgb(85, 100, 165),
    plaqueLight: '#858BA8', // rgb(133,139,168),
    plaqueDark: '#1D233D', // rgb(29,35,61),
    cursor: 'white',
    primary: '#008000',
    secondary: 'plaqueLight',
  },
  // rebass - https://github.com/rebassjs/rebass/blob/master/packages/preset/src/index.js
  fonts: {
    body: '"Lato", "Helvetica Neue", Helvetica, Arial, system-ui, sans-serif',
    heading: '"Aleo", "Times New Roman", Times, serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  breakpoints: [
    '321px', // add +1 to the media.ts breakpoints
    '577px',
    '768px',
    '993px',
    '1201px',
    '1601px',
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    avatar: 48,
  },
  radii: {
    default: 8,
    circle: 99999,
  },
  shadows: {
    card: '0 0 4px rgba(86, 37, 21, .275)',
    text: '0 0 2px rgba(86, 37, 21, .75)',
    textBig: '0 0 3px rgba(86, 37, 21, .95)',
    textDisabled: '0 0 1px rgba(20, 20, 20, 0.2)' 
  },
  // rebass variants
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  variants: {
    item: {
      p: 2,
      bg: 'brickLight',
      border: '1.5px solid',
      borderColor: 'brick',
      boxShadow: 'card',
      color: 'white',
      textShadow: 'text',
      borderRadius: 10,
    },
  },
  buttons: {
    primary: {
      boxSizing: 'border-box',
      fontSize: [2, 3, 4],
      fontWeight: 'bold',
      textShadow: 'text',
      color: 'white',
      bg: 'primary',
      border: '1.5px solid #008000',
      borderRadius: 'default',
      padding: ['0.6em', '0.6em', '0.75em'],
      '&:hover': {
        backgroundColor: '#006C00',
        cursor: 'pointer',
      },
    },
    primaryDisabled: {
      variant: 'buttons.primary',
      color: '#DCDCDC',
      bg: '#ABABAB',
      borderColor: '#DCDCDC',
      '&:hover': {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    },
    outline: {
      variant: 'buttons.primary',
      bg: 'brickVeryLight',
      boxShadow: 'inset 0 0 2px',
      border: '1.5px solid white',
      '&:hover': {
        boxShadow: '0px 0px 0px 1.5px white',
        cursor: 'pointer',
      },
    },
    outlineTiny: {
      variant: 'buttons.outline',
      fontSize: [0, 1, 2],
      border: '1px solid white',
      padding: ['0.5em', '0.6em', '0.75em'],
      '&:hover': {
        boxShadow: 'none',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'background',
      bg: 'secondary',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
};
