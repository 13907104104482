import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { Header } from '../Header';
//import { Footer } from '../Footer';

interface Props {
  children: ReactNode;
}

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-image: url('./bricks_red_3200_overlay_thin.jpg');
  background-position: center;
  background-repeat: repeat;
  background-size: 300%;

  ${media.lg`
    background-size: 200%;
  `}

  ${media.xl`
    background-size: 170%;
  `}

  ${media.xxl`
    background-size: 130%;
  `}
`;

export const Layout = ({ children }: Props): ReactElement => (
  <StyledLayout>
    <Header />
    {children}
  </StyledLayout>
);
