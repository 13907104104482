import React, { ReactElement } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
//import {} from 'styled-components/cssprop'; // https://styled-components.com/docs/api#usage-with-typescript
import { HelmetProvider } from 'react-helmet-async';
import { theme } from '../../styles/theme';
import { NoIE } from './NoIE';
import { MyHelmet } from '../MyHelmet';
import { Layout } from '../Layout';
import { MainWrapper } from '../MainWrapper';
import { Editor } from '../Editor';
import { TwoColumns } from '../TwoColumns';
import { Viewer } from '../Viewer';
import { Gallery } from '../Gallery';

export const App = (): ReactElement => (
  <NoIE>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <MyHelmet />
        <Router>
          <Layout>
            <MainWrapper>
              <Switch>
                <Route exact path="/">
                  <Editor />
                </Route>
                <Route path="/share">
                  <TwoColumns>
                    <Viewer />
                    <Gallery />
                  </TwoColumns>
                </Route>
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </MainWrapper>
          </Layout>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  </NoIE>
);
