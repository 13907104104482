import { CursorMetrics } from '../Sign';
import { getIndex } from './getIndex';
import { normalize } from './normalize';

/**
 * Calculate a new cursor location
 * @param newText
 * @param prevText
 * @param prevCursor -if undefined, will return the 'end' index
 */
export const updateCursorForText = (
  newText: string,
  prevText: string,
  prevCursor: CursorMetrics | undefined
): CursorMetrics => {
  newText = normalize(newText, false);
  prevText = normalize(prevText, false);
  //if new text is longer, cursor moves to the right by the change in char count
  // if new text is shorter, cursor moves left by the change in the char count

  const charDiffCount = newText.length - prevText.length;

  const prevCursorScalar = getIndex(prevText, prevCursor ?? 'end', false);

  return getIndex(newText, prevCursorScalar + charDiffCount);
};
