import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  flex: 1;
  width: 100%;
  margin: 0.25em 0;
`;

interface Props {
  children: ReactNode;
}

export const MainWrapper = ({ children }: Props): ReactElement => {
  return <StyledWrapper>{children}</StyledWrapper>;
};
