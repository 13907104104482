import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from 'rebass/styled-components';
import { MobileView, BrowserView, CustomView, isBrowser, isTablet } from 'react-device-detect';
import { Prompt } from '../Prompt';

interface PromptWrapperProps {
  isMobile: boolean;
}

const PromptWrapper = styled.div<PromptWrapperProps>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'flex-end' : 'center')};
  order: ${({ isMobile }) => (isMobile ? 0 : 1)}; /* move component below sign on non-mobile */
  margin: 0 0.75em 0.5em 0;
  color: white;
  width: 100%;
`;

interface Props {
  isEditing: boolean;
  isMobile: boolean;
  clickHandler: () => void;
}

/**
 * Display device-specific help message
 */
export const KeyboardPrompt = ({ isEditing, isMobile, clickHandler }: Props): ReactElement => (
  <PromptWrapper isMobile={isMobile}>
    <MobileView>
      <Button variant="outlineTiny" margin={'1em'} onClick={clickHandler}>
        Show keyboard
      </Button>
    </MobileView>
    {/** ipad thinks its a tablet - workaround:   */}
    <CustomView condition={isBrowser && !isTablet}>
      <Prompt>
        {`${isEditing ? 'Type using your keyboard' : 'Click the plaque to get started!'}`}
      </Prompt>
    </CustomView>
    <BrowserView></BrowserView>
  </PromptWrapper>
);
