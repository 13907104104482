/**
 * make a single-value query parameter
 * @param key parameter key
 * @param value parameter value
 */
export const parameterize = (key: string, value: string): URLSearchParams => {
  const params = new URLSearchParams();

  if (value) {
    params.append(key, value);
  } else {
    params.delete(key);
  }

  return params;
};
