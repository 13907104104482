/**
 * Replace 'undefined' with an empty string '' and add or remove null chars to blank lines
 * @param text
 * @param blankLineNullChar - if true (default), add a \0 char if a line is blank, if false, remove all \0 chars
 */
export const normalize = (text: string | undefined, blankLineNullChar = true): string => {
  text = text ?? '';

  if (blankLineNullChar) {
    // replace #1: put null after consecutive newlines or newline at the end;  #2: put a null before leading newline
    return text === '' ? '\0' : text.replace(/(.*\n(?=[\n])|\n$)/g, '\n\0').replace(/^\n/, '\0\n');
  }

  return text.replace(/\0/gi, '');
};
