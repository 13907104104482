import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Card, Heading, Text } from 'rebass/styled-components';
import { ProgressiveImage } from '../ProgressiveImage';

const StyledAnchor = styled.a`
  text-decoration: none;
  color: white;

  img {
    border-radius: 0.5em;
    min-width: 225px;
  }
`;

interface Props {
  label: string;
  preview: string;
  anchor: string;
}

export const Item = ({ label, preview, anchor }: Props): ReactElement => (
  <Card variant="item" maxWidth={300}>
    <StyledAnchor
      href={anchor}
      onClick={() => gtag('event', 'view_item', { items: [{ category: `zazzle: ${label}` }] })}
    >
      <ProgressiveImage url={preview} svg="plaque-placeholder-white-background.svg" />
      <Heading textAlign="center">
        <Text>{label}</Text>
      </Heading>
    </StyledAnchor>
  </Card>
);
