import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../../styles/media';

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0.25em;
  color: white;
  background: ${(props): string => props.theme.colors.brickLight};

  h1 {
    display: inline-block;
    font-size: 1.75em;
    text-shadow: ${({ theme }) => theme?.shadows?.textBig};

    &:before {
      content: url('./favicon-16x16.png');
      display: inline-block; /* middle align icon with header text */
      vertical-align: middle;
      margin: 0 0.25em;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }

  ${media.sm`
    h1 {
      font-size: 2.25em;

      &:before {
        margin: 0 0.5em;
      }
    }
  `}

  ${media.md`
    h1 {
      font-size: 2.75em;

      &:before {
        content: url('./favicon-32x32.png');
    }
  `}
`;

export const Header = (): ReactElement => {
  return (
    <StyledHeader>
      <Link to="/">
        <h1>Blue Plaque Generator</h1>
      </Link>
    </StyledHeader>
  );
};
