import { CursorMetrics, CursorPosition } from '../Sign';
import { normalize } from './normalize';

/**
 * Convert between unparsed, scalar indexes (html textarea selectionStart) and CursorMetrics type indexes
 * @param text full text - N.B. the function removes any \0 chars before calculating
 * @param at desired index as a row-char CursorMetrics, number (0 = to left of 1st char) or string 'end' (last index)
 * @param shouldReturnCursor if true (default), return a CursorMetrics, if false newlines treated as ordinary char, returing scalar number
 * @returns either a CustomerMetrics object or a scalar number representing the index of 'at'.
 */
function getIndex(text: string, at: number | 'end'): CursorMetrics;
function getIndex(text: string, at: CursorMetrics | 'end', shouldReturnCursor: false): number;
function getIndex(
  text: string,
  at: CursorMetrics | 'end' | number,
  shouldReturnCursor?: boolean
): number | CursorMetrics {
  // N.B. note the difference between textarea index and cursor
  // leftmost cursor  '|0123' : textarea index 0 = {row: 0, char: 0, position: left}
  // after 1st char   '0|123' : textarea index 1 = {row: 0, char: 0, position: right}

  // default is parsed to rows
  shouldReturnCursor = shouldReturnCursor ?? true;
  text = normalize(text, false); // \0 chars removed from text
  const rows = text.split('\n');

  /* eslint-disable no-case-declarations */
  switch (typeof at) {
    case 'string': // 'end'
      if (at !== 'end') throw new Error("'end' is the only allowed 'at' string value");
      if (!shouldReturnCursor) return text.length;

      const lastRow = rows.length - 1;
  
      return {
        row: lastRow,
        char: Math.max(0, rows[lastRow].length - 1), // -1 -> 0 if row's string is empty
        position: rows[lastRow] === '' ? CursorPosition.Left : CursorPosition.Right,  // left if a blank line
      };

    // convert from textarea index to CursorMetrics
    case 'number':
      const substr = text.substring(0, at);
      return getIndex(substr, 'end');

    // conver CursorMetrics to textarea index
    case 'object':  
      let index = 0;

      for (let i = 0; i <= at.row; i++) {
        if (i < at.row) {
          index += rows[i].length;  // account for lines where all chars count
        } else {
          index += at.char + (at.position === CursorPosition.Right ? 1 : 0); // account for the partial line's chars
        }
      }

      index += at.row  // account for newline chars

      return index;

    default:
      throw new Error("Unhandled 'at' type");
  }
}

export { getIndex };
