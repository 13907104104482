import React, { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

const PromptWrapper = styled.p`
  font-size: 1.5em;
  padding: 0.25em;
  background-color: ${({ theme }) => theme?.colors?.brickVeryLight};
  color: ${({ theme }) => theme?.colors?.brickDark}; ;
`;

interface Props {
  children: ReactNode;
}

export const Prompt = ({ children }: Props): ReactElement => (
  <PromptWrapper>{children}</PromptWrapper>
);
