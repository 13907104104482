import React, { ReactElement, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import categories from './zazzle/categories.json';
import { zazzlePage, productPreviewThumb } from './lib/links';
import { Item } from './Item';
import { useQueryParams } from '../Editor';

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > div {
    margin: 0.75em 0.5em;

    ${media.md`
      margin: 1em;
    `}
  }
`;

interface Category {
  name: string;
  catId: string;
  prodId: string;
}

const renderItems = (text: string, categories: Category[]): ReactNode[] => {
  return categories.map(({ name, catId, prodId }) => {
    return (
      <Item
        key={name}
        label={name}
        preview={productPreviewThumb(text, prodId)}
        anchor={zazzlePage(text, catId)}
      />
    );
  });
};

export const Gallery = (): ReactElement => {
  const params = useQueryParams();
  const text = params.get('plaque') || '';

  // try to 'fire and forget' pre-fetch hi-res plaque - zazzle will cache on subsequent calls - speeds up store image clicks
  useEffect(() => {
    // zazzle cors is not enabled - so must use 'no-cors' https://stackoverflow.com/questions/45696999/fetch-unexpected-end-of-input
    // sometimes returns 403 errors - tried many things, including .then, setTimeout, wrapping in async - nothing fixed it
    fetch(zazzlePage(text, categories[0].catId), { mode: 'no-cors' })
      //.then((res) => console.log(res))
      .catch((e) => console.log(e));
  }, []);

  return <GalleryWrapper>{renderItems(text, categories)}</GalleryWrapper>;
};
