import React, { ReactElement, ReactNode } from 'react';
import { media } from '../../styles/media';
import styled from 'styled-components';

interface Props {
  children: ReactNode[];
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  width: 100%;
  margin-top: 0.5em;

  ${media.md`
    margin-top: 1em;
  `}

  ${media.lg`
    flex-wrap: nowrap; // retains 2 column layout on tablet landscape
  `}
`;

export const TwoColumns = ({ children }: Props): ReactElement => <Wrapper>{children}</Wrapper>;
