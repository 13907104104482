import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { Image as RebassImage } from 'rebass/styled-components';

interface Props {
  /**
   * url of full-resolution image
   */
  url: string;
  /**
   * placeholder svg
   */
  svg: string;
}

/**
 * Load placeholder image
 * https://medium.com/@perjansson/a-progressive-image-loader-in-react-f14ae652619d
 */
export const ProgressiveImage = ({ url, svg }: Props): ReactElement => {
  const [loading, setLoading] = useState(true);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const style = (loading: boolean) => {
    return {
      width: '100%',
      transition: '0.75s filter linear',
      filter: `${loading ? 'blur(8px)' : ''}`,
    };
  };

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setLoading(false);
      if (imgRef.current) {
        imgRef.current.src = image.src;
      }
    };

    image.src = url;
  }, []);

  return <RebassImage src={process.env.PUBLIC_URL + svg} sx={style(loading)} ref={imgRef} />;
};
