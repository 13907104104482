import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { ViewerSign } from './ViewerSign';
import { plaqueUrl, ImageType } from '../Gallery';
import { useQueryParams } from '../Editor';

const ViewerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${media.md`
    margin: 0 2em;
    max-width: 600px;
    min-width: 500px;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin: 1em 1em;
  }

  ${media.md`
    margin: 1.5em 1em;
  `}
`;

/**
 * Viewer Component
 */
export const Viewer = (): ReactElement => {
  const params = useQueryParams();
  const text = params.get('plaque') || '';
  const url = plaqueUrl(text, ImageType.Download);

  return (
    <>
      <ViewerWrapper>
        <ViewerSign url={url} />
        <ButtonWrapper>
          <Link
            to={`/?${params.toString()}`}
            onClick={() => gtag('event', 'share', { content_type: 'download' })}
          >
            <Button variant="outline">Edit</Button>
          </Link>
          <a href={url} download="plaque.png">
            <Button>Free Download</Button>
          </a>
        </ButtonWrapper>
      </ViewerWrapper>
    </>
  );
};
