import React, { ReactElement, Dispatch, MouseEvent } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { CursorPosition } from './Sign';
import { letterSource } from './lib/letters';
import { EditorActions } from './lib/reducer';

type CharacterIndex = { row: number; char: number };

interface CharacterProps {
  index: CharacterIndex;
  letter: string;
  cursorPosition?: CursorPosition;
  dispatch: Dispatch<EditorActions>;
}

export type CharacterMetrics = Omit<CharacterProps, 'dispatch' | 'cursorIndex'>;

interface StyledCharProps {
  yOffset: number;
  width: number;
  height: number;
  cursorPosition?: CursorPosition;
}

const StyledChar = styled.li<StyledCharProps>`
  flex: none;
  list-style: none;
  transform: scale(${320 / 500});
  margin: -0.75rem -0.45rem;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  background: url('./letters_63w.png') no-repeat;
  background-position: ${({ yOffset }) => `0px ${yOffset}px`};

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

  animation: ${({ cursorPosition }) => (cursorPosition ? 'blink 1.2s step-end infinite' : 'none')};
  border-left: ${({ cursorPosition, theme }) =>
    cursorPosition === 'left' ? `solid 1.75px ${theme.colors.cursor}` : 'none'};
  border-right: ${({ cursorPosition, theme }) =>
    cursorPosition === 'right' ? `solid 1.75px ${theme.colors.cursor}` : 'none'};

  ${media.sm`
    transform: scale(1);
    margin: 0;
  `}
`;

const handleClick = (
  event: MouseEvent,
  index: CharacterIndex,
  dispatch: Dispatch<EditorActions>
): void => {
  const clickX = event.nativeEvent.offsetX / event.currentTarget.clientWidth;
  const position = clickX < 0.5 ? CursorPosition.Left : CursorPosition.Right;
  dispatch({ type: 'cursor/signClick', payload: { ...index, position } });
};

export const Character = ({ index, letter, cursorPosition, dispatch }: CharacterProps): ReactElement => {
  const { y, w, h } = letterSource(letter);

  return (
    <StyledChar
      yOffset={y}
      width={w}
      height={h}
      cursorPosition={cursorPosition}
      onClick={(e) => handleClick(e, index, dispatch)}
    />
  );
};

/**
 * Sum the character widths
 * @param characters 
 * @param indexEnd - up to but not including this character; if undefined (default), all characters are counted
 */
export const charsWidth = (characters: CharacterMetrics[], indexEnd?: number): number => {
  return characters.reduce((tot, {letter}, i) => {
    if (i < (indexEnd ?? characters.length)) {
      tot += letterSource(letter).w;
    }

    return tot;
  }, 0);
};
