/* eslint-disable @typescript-eslint/no-explicit-any */
import { CursorMetrics } from '../Sign';
import { getIndex } from './getIndex';
import { normalize } from './normalize';
import { updateCursorForText } from './updateCursorForText';
import { updateCursorForArrow } from './updateCursorForArrow';

export interface SignState {
  text: string;
  cursor: CursorMetrics;
  isEditing: boolean;
}

export interface Action<T extends string, K> {
  type: T;
  payload?: K;
}

export type EditorActions =
  | Action<'cursor/signClick', CursorMetrics>
  | Action<'cursor/textareaArrowKey', string>
  | Action<'text/update', string>
  | Action<'editing/toggle', boolean>;

/**
 * React reducer
 * @param state state object
 * @param action
 */
export const reducer = (prevState: SignState, { type, payload }: EditorActions): SignState => {
  switch (type) {
    case 'cursor/signClick':
      payload == payload ?? getIndex(prevState.text, 'end');

      if (!(typeof payload === 'object' && 'row' in payload)) throw new Error('Bad payload type');

      return { ...prevState, cursor: payload };

    case 'cursor/textareaArrowKey':
      if (typeof payload !== 'string') throw new Error('Bad payload type');

      return {
        ...prevState,
        cursor: updateCursorForArrow(payload, prevState.text, prevState.cursor),
      };

    case 'text/update':
      if (typeof payload !== 'string') throw new Error('Bad payload type');

      return {
        text: normalize(payload, false),
        cursor: updateCursorForText(payload, prevState.text, prevState.cursor),
        isEditing: true,
      };

    case 'editing/toggle':
      if (typeof payload !== 'boolean') throw new Error('Bad payload type');

      return {
        ...prevState,
        isEditing: payload,
      };

    default:
      throw new Error('unknown action type in reducer');
  }
};
