import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactElement[];
}

const StyledRows = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 2em 0 -1.5em 0; /* push rows below the center of the sign */
  max-width: 100%; /* constrain widths needed on xs screens */
`;

export const Rows = ({ children }: Props): ReactElement => <StyledRows>{children}</StyledRows>;
