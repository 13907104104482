import { CursorMetrics } from '../Sign';
import { getIndex } from './getIndex';
import { calculateLayout } from './calculateLayout';

/**
 * Calculate a new cursor location
 * @param arrow
 * @param prevText
 * @param prevCursor -if undefined, will return the 'end' index
 */
export const updateCursorForArrow = (
  arrow: string,
  prevText: string,
  prevCursor: CursorMetrics
): CursorMetrics => {
  /* eslint-disable no-case-declarations */
  switch (arrow) {
    case 'ArrowLeft':
    case 'ArrowRight':
      const prevCursorScalar = getIndex(prevText, prevCursor ?? 'end', false);
      return getIndex(prevText, prevCursorScalar + (arrow === 'ArrowLeft' ? -1 : 1));

    case 'ArrowUp':
    case 'ArrowDown':
      const { row, char, position } = prevCursor;
      const layout = calculateLayout(prevText);
      // handle case where cannot go further up or down
      if (
        (arrow === 'ArrowUp' && row === 0) ||
        (arrow === 'ArrowDown' && row === layout.length - 1)
      ) {
        return prevCursor;
      }

      const newRow = row + (arrow === 'ArrowUp' ? -1 : 1);
      const newCharCount = layout[newRow].characterMetrics.length;

      let newChar: number;
      // if @start/end of row, remain @start/end on the newRow.  Otherwise, do not change char
      if (char === layout[row].characterMetrics.length - 1) {
        newChar = Math.max(0, newCharCount - 1);
      } else {
        newChar = Math.min(char, newCharCount - 1);
      }

      return {
        row: newRow,
        char: newChar,
        position,
      };

    default:
      return prevCursor;
  }
};
