export interface CharSource {
  char: number;
  y: number;
  w: number;
  h: number;
}

const FIRST_CHARCODE = 32; //char code of first char in letter source image

const letterWidths: {
  [key: number]: number;
} = {
  0: 0.01, //  \u0000 NULL
  8: 0, //  backspace
  10: 0, //  \n newline
  13: 0, //  \r endline
  27: 0, //  ESC
  32: 0.3, //   space
  33: 0.45, //   !
  34: 0.7, //
  35: 0.75, //   #
  36: 0.75, //   $
  37: 0.95, //   %
  38: 0.85, //   &
  39: 0.4, //   '
  40: 0.45, //   (
  41: 0.45, //   )
  42: 0.55, //   *
  43: 0.75, //   +
  44: 0.4, // ,
  45: 0.5, //   -
  46: 0.3, //   .
  47: 0.4, //   /
  48: 0.925, //  0
  49: 0.4, //  1
  50: 0.75, //  2
  51: 0.7, //  3
  52: 0.85, //  4
  53: 0.65, //  5
  54: 0.7, //  6
  55: 0.7, //  7
  56: 0.65, //  8
  57: 0.7, //  9
  58: 0.35, //  :
  59: 0.35, //   ;
  60: 0.8, //   <
  61: 0.8, //   =
  62: 0.8, //   >
  63: 0.5, //   ?
  64: 0.9, //   @
  65: 0.825, //   A
  66: 0.65, //   B
  67: 0.8, //   C
  68: 0.875, //   D
  69: 0.65, //   E
  70: 0.625, //   F
  71: 0.8, //   G
  72: 0.89, //   H
  73: 0.325, //   I
  74: 0.475, //   J
  75: 0.75, //   K
  76: 0.6, //   L
  77: 0.875, //   M
  78: 0.875, //   N
  79: 0.875, //   O
  80: 0.6, //   P
  81: 1.05, //   Q
  82: 0.825, //   R
  83: 0.6, //   S
  84: 0.75, //   T
  85: 0.85, //   U
  86: 0.8, //   V
  87: 0.9, //   W
  88: 0.825, //   X
  89: 0.9, //   Y
  90: 0.8, //   Z
  91: 0.5, //   [
  92: 0.4, //   \
  93: 0.4, //   ]
  94: 0.7, //   ^
  95: 0.7, //   _
  96: 0.4, //   `
  97: 0.5, //   a
  98: 0.65, //   b
  99: 0.55, //   c
  100: 0.6, //   d
  101: 0.55, //   e
  102: 0.5, //   f
  103: 0.55, //   g
  104: 0.6, //   h
  105: 0.3, //   i
  106: 0.4, //   j
  107: 0.55, //   k
  108: 0.3, //   l
  109: 0.9, //   m
  110: 0.55, //   n
  111: 0.6, //   o
  112: 0.65, //   p
  113: 0.65, //   q
  114: 0.45, //   r
  115: 0.5, //   s
  116: 0.5, //   t
  117: 0.65, //   u
  118: 0.575, //   v
  119: 0.85, //   w
  120: 0.6, //   x
  121: 0.65, //   y
  122: 0.6, //   z
  123: 0.4, //   {
  124: 0.35, //   |
  125: 0.4, //    }
  126: 0.7, //  ~
};

/**
 * Returns size info on character - info to extract letter from letter source image
 * @param {number} charCode - character code
 * @return {object} object with four keys:  x,y,w,h
 */

export const letterSource = (letter: string): CharSource => {
  // replace unknown chars with "?"
  const letterCode = letter.charCodeAt(0) || 10; // newline 'letter' is the empty string (''.charCodeAt(0) = NaN) - handle
  const char = Object.keys(letterWidths).includes(letterCode.toString()) ? letterCode : 63;

  return {
    char,
    y: -1 * Math.round(64.556 * (char - FIRST_CHARCODE)), // location of char in src;  y is negative for background img offsets
    w: Math.round(63 * letterWidths[char]), // source width * letter width factor
    h: char === 10 ? 40 : 60, // letter height - newline height is narrower
  };
  
};

