import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * MyHelmet Head tags
 * https://open.nytimes.com/the-future-of-meta-tag-management-for-modern-react-development-ec26a7dc9183
 */
export const MyHelmet = (): ReactElement => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Blue Plaque Generator</title>
    <link rel="canonical" href="https://blueplaque.com" />
    <link rel="icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />
    {/* https://realfavicongenerator.net  */}
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5564a5" />
    <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/logo192.png`} />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
    />
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />
    <meta
      name="description"
      content="Free online London Blue Plaque maker.  Winston Churchill has one.  So does Florence Nightingale.  Why not you?"
    />
    <meta name="keywords" content="London Blue Plaque Generator Free Online" />
    <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />
    {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG_ID}`}
    ></script>
    <script>
      {`
              window['ga-disable-${process.env.REACT_APP_GTAG_ID}'] = ${!process.env
        .REACT_APP_GTAG_ID};
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_GTAG_ID}');
              gtag('consent', 'default', {analytics_storage: 'denied'});
            `}
    </script>
  </Helmet>
);
