const associateId = '238431727879806518';
const storeId = '250725018636061490';
const zazzleBase = `https://www.zazzle.co.uk`;

export enum ImageType {
  Zazzle = 'zazzle',
  Download = 'download',
  Gallery = 'gallery',
}

export const plaqueUrl = (text: string, type: ImageType): string => {
  // NB - for unknown reasons, zazzle links do not work reliably if domain alias is used, so only use alias for downloads
  const baseUrl =
    type === ImageType.Download
      ? process.env.REACT_APP_API_ALIAS ?? process.env.REACT_APP_API
      : process.env.REACT_APP_API;

  const queryString = encodeURI(text);
  return `${baseUrl}/${type}/my-plaque.png?text=${queryString}`;
};

/**
 * generate Zazzle URL for image
 * @param text - plaque text
 * @param identifier - productID for Link, categoryID for design blast
 * @param isBlast - if true, link to category ("Design Blast"), if false, product
 * @param trackingCode - optional Zazzle tracking code (alphanumeric), default ''
 * @return URI encoded Url to zazzle endpoint for image (zazzleBase + image.png)
 */
export const zazzlePage = (
  text: string,
  identifier: string,
  isBlast = true,
  trackingCode?: string
): string => {
  const url = new URL(`api/create/at-${associateId}`, zazzleBase);

  const iid = plaqueUrl(text, ImageType.Zazzle);

  const params = {
    rf: associateId,
    ax: isBlast ? 'DesignBlast' : 'Linkover', // single product 'Linkover', for buffet:  'Design'
    ed: 'false', // user-editable?
    tc: trackingCode, // tracking code - 100 alphanumeric + _
    ic: encodeURI(text), // extract id from imageName as image tracking code - 100 alphanumeric + _
    t_image1_iid: iid,
    t_image2_iid: iid,
    sr: storeId,
    fwd: isBlast ? 'productpage' : undefined,
    cg: isBlast ? identifier : undefined,
    pd: !isBlast ? identifier : undefined,
  };

  const search = url.searchParams;
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      search.set(key, value.toString());
    }
  });

  url.search = search.toString();

  return url.toString(); // = .href
};

/**
 * generate object with items needed to create 'real time views'
 * @param text - plaque text
 * @param productID - Zazzle productID
 * @param size - size of returned image in px (eg. 128, 256, 512)
 * @return - {href: for anchor, src: for image, size: of image}
 */
export const productPreviewThumb = (text: string, productId: string, size = 225): string => {
  const url = new URL('svc/view', 'https://rlv.zazzle.com');

  // params with link to zazzle thumbnail for rlv display
  const params = {
    at: associateId,
    pid: productId,
    t_image1_url: plaqueUrl(text, ImageType.Gallery),
    t_image2_url: plaqueUrl(text, ImageType.Gallery),
    max_dim: size, // The size of the image you would like returned. Commonly 128, 256 or 512
  };

  const search = url.searchParams;
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      search.set(key, value.toString());
    }
  });

  url.search = search.toString();

  return url.href;
};
