const scalingForLine = (lineNum: number, lineCount: number): number => {
  // scaling factor: 1st dimension # of lines, 2nd dimension array containing rel length of each line
  const factors = [
    [1], // if 1 row
    [1, 1], // if two rows
    [0.9, 1, 0.9],
    [0.8, 1, 1, 0.8],
    [0.66, 0.9, 1, 0.9, 0.66],
    [0.6825, 0.8, 1, 1, 0.7, 0.575],
  ];

  // for signs with 7+ lines, use 6-line factors
  lineCount = Math.min(lineCount, factors.length);
  lineNum = Math.min(lineNum, 5);

  return factors[lineCount - 1][lineNum];
};

export const scale = (
  unconstrainedW: number,
  lineNum: number,
  lineCount: number,
  printableW: number
): number => {
  // what is ratio of letter widths to sign base?
  const availableWidthFactor = printableW * scalingForLine(lineNum, lineCount);

  const percentageOccupied = unconstrainedW / (500 * availableWidthFactor);

  // return resize factor?  for shorter ratios 1, for bigger ratios inverse of ratio
  return Math.min(1 / percentageOccupied, 1);
};
