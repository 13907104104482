import React, {
  ReactElement,
  ChangeEvent,
  KeyboardEvent,
  Dispatch,
  useReducer,
  useRef,
  useEffect,
  MutableRefObject,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Button } from 'rebass/styled-components';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { reducer, SignState, EditorActions } from './lib/reducer';
import { getIndex } from './lib/getIndex';
import { normalize } from './lib/normalize';
import { parameterize } from './lib/parameterize';
import { useQueryParams } from './lib/useQueryParams';
import { Sign } from './Sign';
import { KeyboardPrompt } from './KeyboardPrompt';

const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  order: 3;

  button {
    margin: 1em 1em;
  }

  ${media.md`
    margin: 1.5em 0em;
  `}
`;

const DummyForm = styled.form`
  display: inline-block;
  position: absolute; // abs or rel positioning needed for z-index to have effect
  top: -200%;
  width: 100%;
`;

const DummyInput = styled.textarea`
  /* position: relative;
  top: -50%;
  left: 0%; */
`;

/**
 * onChange event handler
 */
const handleChange = (
  { target: { value } }: ChangeEvent<HTMLTextAreaElement>,
  dispatch: Dispatch<EditorActions>
): void => {
  dispatch({ type: 'text/update', payload: value });
};

/**
 * onKeydown event handler
 */
const handleKeydown = (
  { key }: KeyboardEvent<HTMLTextAreaElement>,
  dispatch: Dispatch<EditorActions>
): void => {
  if (!['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(key)) return;

  dispatch({ type: 'cursor/textareaArrowKey', payload: key });
};

/**
 * start over button event handler
 */
const handleResetClick = (dispatch: Dispatch<EditorActions>): void => {
  dispatch({ type: 'text/update', payload: '' });
};

/**
 * show keyboard button event handler
 * iOS does not display the keyboard if the the ref is focussed in useEffect.  But keyboard display works using this, even though (?) focus is a side-effect
 * SO post https://stackoverflow.com/a/16601288 perhaps relevant - the internets have more questions than answers around programmatic iOS keyboard display
 */
const showMobileKeyboard = (
  textareaRef: MutableRefObject<HTMLTextAreaElement | null>,
  dispatch: Dispatch<EditorActions>
): void => {
  textareaRef?.current?.focus();
  dispatch({ type: 'editing/toggle', payload: true });
};

/**
 * Editor Component
 */
export const Editor = (): ReactElement => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const history = useHistory();
  const params = useQueryParams();

  const defaultText = params.get('plaque') ?? '';
  const defaultState: SignState = {
    text: defaultText,
    cursor: getIndex(defaultText, 'end'),
    isEditing: true,
  };
  const [state, dispatch] = useReducer(reducer, defaultState);

  // handle textarea focus
  useEffect(() => {
    if (state.isEditing) {
      const textAreaCursorIndex = getIndex(state.text, state.cursor, false);
      textareaRef?.current?.setSelectionRange(textAreaCursorIndex, textAreaCursorIndex);
      textareaRef?.current?.focus();
    } else {
      textareaRef?.current?.blur();
    }
  }, [state.isEditing]);

  const makePlaqueParameter = (): string => {
    const text = normalize(state.text, false);
    const params = parameterize('plaque', text);
    return params.toString();
  };

  // query params hold plaque text
  useEffect(() => {
    history.push({ search: makePlaqueParameter() });
  }, [state.text, history]);

  return (
    <>
      <EditorWrapper>
        <KeyboardPrompt
          isEditing={state.isEditing}
          isMobile={isMobile}
          clickHandler={() => showMobileKeyboard(textareaRef, dispatch)}
        />
        <Sign
          text={state.text}
          cursor={state.cursor}
          editing={state.isEditing}
          dispatch={dispatch}
        />
        <ButtonWrapper>
          <Button variant="outline" onClick={() => handleResetClick(dispatch)}>
            Start Over
          </Button>
          <Link
            to={state.text ? `/share?${makePlaqueParameter()}` : '/'}
            style={state.text ? {} : { cursor: 'not-allowed' }}
          >
            <Button variant={state.text ? 'primary' : 'primaryDisabled'}>Share</Button>
          </Link>
        </ButtonWrapper>
      </EditorWrapper>
      <DummyForm>
        <DummyInput
          name="dummyInput"
          id="dummyInput"
          ref={textareaRef}
          onChange={(e) => handleChange(e, dispatch)}
          onKeyDown={(e) => handleKeydown(e, dispatch)}
          onBlur={() => dispatch({ type: 'editing/toggle', payload: false })}
          maxLength={200}
          rows={10}
          wrap="hard"
          value={state.text}
        />
      </DummyForm>
    </>
  );
};
