import { charsWidth } from '../Character';
import { RowMetrics } from '../Row';
import { scale } from './scale';

const PRINTABLE_WIDTH_PERCENT = 0.82;

export const calculateLayout = (text: string): RowMetrics[] => {
  const rows = text.split('\n');

  return rows.map((row, i) => {
    // replace blank newlines with null char - ensure all rows have min one Character
    row = row.length > 0 ? row : '\0';

    const characterMetrics = row.split('').map((letter, j) => {
      const index = { row: i, char: j };
      return { index, letter };
    });

    const unconstrainedRowW = charsWidth(characterMetrics);
    const scaling = scale(unconstrainedRowW, i, rows.length, PRINTABLE_WIDTH_PERCENT);

    return {
      rowIndex: i,
      characterMetrics,
      scaling,
    };
  });
};
