import React, { ReactElement } from 'react';
//import { Image } from 'rebass/styled-components';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { ProgressiveImage } from '../ProgressiveImage';

const SignWrapper = styled.div`
  ${media.sm`
    margin: 0.5em 0;
  `}
`;

interface Props {
  url: string;
}

export const ViewerSign = ({ url }: Props): ReactElement => {
  return (
    <SignWrapper>
      <ProgressiveImage url={url} svg="plaque-placeholder.svg" />
    </SignWrapper>
  );
};
