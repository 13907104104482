/* !!!eslint-disable @typescript-eslint/ban-ts-ignore */
// https://www.styled-components.com/docs/advanced#media-templates
import { css } from 'styled-components';

// follows antd / bootstrap - https://ant.design/components/grid/
const breakpoints = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

// at least queries
const xs = `(max-width: ${breakpoints.xs / 16}em)`;
const sm = `(min-width: ${breakpoints.sm / 14}em)`;
const md = `(min-width: ${breakpoints.md / 16}em)`;
const lg = `(min-width: ${breakpoints.lg / 16}em)`;
const xl = `(min-width: ${breakpoints.xl / 16}em)`;
const xxl = `(min-width: ${breakpoints.xxl / 16}em)`;

type MediaQuery = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'mdPortrait';

// combine in output object
const queries = {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  mdPortrait: `${md} and (orientation : portrait)`,
};

const isMediaQuery = (query: string): query is MediaQuery => {
  return Object.keys(queries).includes(query);
};

/**
 * Media query mixin -- tagged template literals for desktop, tablet, phone breakpoints
 * Iterate through the sizes and create a media template
 * append @media directive here - queries also used without @media in useMedia hook
 * @return {Object} Object with media type keys, value tagged template literal
 */

// arrghh, typings: https://stackoverflow.com/questions/49397538/typing-the-css-function-in-styled-components
/* eslint-disable @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any  */
const media = {} as Record<MediaQuery, (l: TemplateStringsArray, ...p: any[]) => string>;

for (const query in queries) {
  if (!isMediaQuery(query)) {
    throw new Error(`'${query} is not a recognized media query'`);
  }
  media[query] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
    css`
      ${'@media ' + queries[query]} {
        ${css(literals, ...placeholders)}
      }
    `.join('');
}

export { media, queries, isMediaQuery };
