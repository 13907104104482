import React, { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

/**
 * Do not support IE
 * //https://stackoverflow.com/a/21712356
 */
export const NoIE = ({ children }: Props): ReactElement => {
  const ua = window.navigator.userAgent;
  const isIE = /MSIE|Trident/.test(ua);

  return isIE ? (
    <p>Blueplaque.com does not support the Internet Explorer browser. Sorry.</p>
  ) : (
    <>{children}</>
  );
};
