import React, { ReactElement, Dispatch } from 'react';
import styled from 'styled-components';
import { Character, CharacterMetrics } from './Character';
import { CursorMetrics } from './Sign';
import { EditorActions } from './lib/reducer';

interface RowProps {
  rowIndex: number;
  characterMetrics: CharacterMetrics[];
  scaling: number;
  cursorMetrics: CursorMetrics;
  editing: boolean;
  dispatch: Dispatch<EditorActions>;
}

export type RowMetrics = Omit<RowProps, 'dispatch' | 'cursorMetrics' | 'editing'>;

interface StyledRowProps {
  scaling: number;
}

const StyledRowWrapper = styled.li`
  list-style: none;
`;

const StyledRow = styled.ul<StyledRowProps>`
  display: flex;
  justify-content: center;
  align-content: center;
  transform: ${({ scaling }) => `scale(${scaling})`};
  margin: ${({ scaling }) => `${scaling - 1}em 0em`}; /* decrease row h for scaled rows */
`;

/**
 * Row renderer function
 * @param rowProps
 */
const renderRowCharacters = (rowProps: RowProps): ReactElement[] => {
  const { characterMetrics, cursorMetrics, editing, dispatch } = rowProps;

  // blank row will have 0 characters - insert a narrow dummy character
  return characterMetrics.map(({ index, letter }) => (
    <Character
      key={`char-${index.row}-${index.char}`}
      index={index}
      letter={letter}
      cursorPosition={
        editing && index.row === cursorMetrics.row && index.char === cursorMetrics.char
          ? cursorMetrics.position
          : undefined
      }
      dispatch={dispatch}
    />
  ));
};

/**
 * Row Component
 * @param props
 */
export const Row = (props: RowProps): ReactElement => {
  return (
    <StyledRowWrapper>
      <StyledRow scaling={props.scaling}>{renderRowCharacters(props)}</StyledRow>
    </StyledRowWrapper>
  );
};
