import React, { ReactElement, useMemo, Dispatch } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { Rows } from './Rows';
import { Row, RowMetrics } from './Row';
import { calculateLayout } from './lib/calculateLayout';
import { EditorActions } from './lib/reducer';

export enum CursorPosition {
  Left = 'left',
  Right = 'right',
}

export interface CursorMetrics {
  row: number;
  char: number;
  position: CursorPosition;
}

interface SignProps {
  text: string;
  cursor: CursorMetrics;
  editing: boolean;
  dispatch: Dispatch<EditorActions>;
}

const SignWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 320px;
  height: 320px; /* explicit height needed so full sign displays on empty/short text */

  background-size: cover;
  background-position: center;
  background-image: url('./plaque_mortar_london_500.png');

  ${media.sm`
    width: 500px;
    height: 500px;
    margin: 0.5em 0;
  `}
`;

const renderRows = (
  rowMetrics: RowMetrics[],
  cursorMetrics: CursorMetrics,
  editing: boolean,
  dispatch: Dispatch<EditorActions>
): ReactElement[] => {
  return rowMetrics.map((row) => (
    <Row
      key={'row-' + row.rowIndex}
      rowIndex={row.rowIndex}
      scaling={row.scaling}
      characterMetrics={row.characterMetrics}
      cursorMetrics={cursorMetrics}
      editing={editing}
      dispatch={dispatch}
    />
  ));
};

export const Sign = ({ text, cursor, editing, dispatch }: SignProps): ReactElement => {
  const layout = useMemo(() => calculateLayout(text), [text]);

  return (
    <SignWrapper onClick={() => dispatch({ type: 'editing/toggle', payload: true })}>
      <Rows>{renderRows(layout, cursor, editing, dispatch)}</Rows>
    </SignWrapper>
  );
};
